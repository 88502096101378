import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";

// Import both WebP and fallback images
import laptopImgWebP from "../../Assets/about.webp";
import laptopImgFallback from "../../Assets/about.png";

function About() {
  return (
    <Container fluid className="about-section" id="about">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong>LEARN MORE ABOUT ME</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <picture>
              <source srcSet={laptopImgWebP} type="image/webp" />
              <source srcSet={laptopImgFallback} type="image/png" />
              <img src={laptopImgFallback} alt="About" className="img-fluid" />
            </picture>
          </Col>
        </Row>
        <h1 className="project-heading">
          <strong className="purple">SKILLS</strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">TOOLS</strong> I USE
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;

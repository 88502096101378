import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// Import images in both WebP and fallback formats
import liartWebP from "../../Assets/Projects/liart.io.webp";
import liartFallback from "../../Assets/Projects/liart.io.png";

import dermaWebP from "../../Assets/Projects/derma.webp";
import dermaFallback from "../../Assets/Projects/derma.png";

import genieWebP from "../../Assets/Projects/genie.webp";
import genieFallback from "../../Assets/Projects/genie.png";

import quizardcraftWebP from "../../Assets/Projects/quizardcraft.webp";
import quizardcraftFallback from "../../Assets/Projects/quizardcraft.png";

import renderramaWebP from "../../Assets/Projects/renderrama.webp";
import renderramaFallback from "../../Assets/Projects/renderrama.png";

import visualizeitWebP from "../../Assets/Projects/visualizeit.webp";
import visualizeitFallback from "../../Assets/Projects/visualizeit.png";

function Projects() {
  return (
    <Container fluid className="project-section" id="projects">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I&apos;ve worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPathWebP={liartWebP}
              imgPathFallback={liartFallback}
              isBlog={false}
              title="liart.io"
              description="Free online multiplayer game that blends elements of two popular games Skribbl & Among Us"
              demoLink="https://liart.io"
            />
          </Col>

          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPathWebP={dermaWebP}
              imgPathFallback={dermaFallback}
              isBlog={false}
              title="Derma"
              description="AI skin health assistant which helps to identify your possible skin condition"
              ghLink="https://github.com/suravshrestha/derma"
            />
          </Col>

          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPathWebP={genieWebP}
              imgPathFallback={genieFallback}
              isBlog={false}
              title="Genie"
              description="AI coding assistant which enhances your coding experience"
              ghLink="https://github.com/suravshrestha/genie"
              demoLink="https://www.youtube.com/watch?v=OM46MWUFTTs"
            />
          </Col>

          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPathWebP={quizardcraftWebP}
              imgPathFallback={quizardcraftFallback}
              isBlog={false}
              title="QuizardCraft"
              description="AI learning assistant which helps to generate flashcards based on your topic of interest"
              ghLink="https://github.com/suravshrestha/QuizardCraft"
              demoLink="https://www.youtube.com/watch?v=nnn-W8kpRO8"
            />
          </Col>

          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPathWebP={renderramaWebP}
              imgPathFallback={renderramaFallback}
              isBlog={false}
              title="RenderRama"
              description="An interactive visualizer for 2D and 3D transformations, along with curve modeling"
              ghLink="https://github.com/suravshrestha/RenderRama"
              demoLink="https://renderrama.vercel.app/"
            />
          </Col>

          <Col lg={4} md={6} className="project-card">
            <ProjectCard
              imgPathWebP={visualizeitWebP}
              imgPathFallback={visualizeitFallback}
              isBlog={false}
              title="VisualizeIT"
              description="Interactive visualization tool for pathfinding, backtracking, and recursion algorithms"
              ghLink="https://github.com/suravshrestha/visualizeit"
              demoLink="https://visualizeit.vercel.app/"
              className="text-center"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import PropTypes from "prop-types";  // Import PropTypes
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

const ProjectCards = (props) => {
  return (
    <Card
      className="project-card-view"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <picture>
        <source srcSet={props.imgPathWebP} type="image/webp" />
        <source srcSet={props.imgPathFallback} type="image/jpeg" />
        <img
          src={props.imgPathFallback}
          alt="Project"
          className="card-img-top"
        />
      </picture>

      <Card.Body style={{ flexGrow: 1 }}>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "center", flexGrow: 1 }}>
          {props.description}
        </Card.Text>
      </Card.Body>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1.2rem",
        }}
      >
        {props.ghLink && (
          <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub />
            &nbsp;{props.isBlog ? "Blog" : "GitHub"}
          </Button>
        )}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite />
            &nbsp;Demo
          </Button>
        )}
      </div>
    </Card>
  );
};

// Add PropTypes validation
ProjectCards.propTypes = {
  imgPathWebP: PropTypes.string.isRequired,       // WebP image path
  imgPathFallback: PropTypes.string.isRequired,   // Fallback image path (e.g., JPEG)
  title: PropTypes.string.isRequired,             // Title of the project
  description: PropTypes.string.isRequired,       // Project description
  ghLink: PropTypes.string,                       // GitHub link (optional)
  isBlog: PropTypes.bool,                         // Boolean indicating if it's a blog
  demoLink: PropTypes.string,                     // Demo link (optional)
};

export default ProjectCards;

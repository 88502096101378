import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <p>
              I&apos;m an undergrad student pursuing Bachelor of Computer Engineering
              (BCT) under IOE, Pulchowk Campus, Tribhuwan University.
            </p>
            <p>
              With a passion for{" "}
              <strong className="purple">Software Engineering</strong>,{" "}
              <strong className="purple">Cloud Computing</strong>, and{" "}
              <strong className="purple">DevOps</strong>, my area of expertise
              includes <strong className="purple">web development</strong>,{" "}
              <strong className="purple">problem-solving</strong>,{" "}
              <strong className="purple">data structures and algorithms</strong>
              , and <strong className="purple">programming in general</strong>.
            </p>
            <p>
              Experienced in programming with{" "}
              <strong className="purple">
                C++, JavaScript, TypeScript, Python
              </strong>{" "}
              including web development with{" "}
              <strong className="purple">MERN</strong> stack,{" "}
              <strong className="purple">Django</strong> and designing{" "}
              <strong className="purple">RESTful APIs.</strong>
            </p>
            Apart from coding, I love
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight />
              &nbsp; Watching TV series and movies
            </li>
            <li className="about-activity">
              <ImPointRight />
              &nbsp; Listening to music
            </li>
            <li className="about-activity">
              <ImPointRight />
              &nbsp; Solving Rubik&apos;s cube
            </li>
            <li className="about-activity">
              <ImPointRight />
              &nbsp; Playing chess
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            &quot;Stay hungry. Stay foolish.&quot;{" "}
          </p>
          <footer className="blockquote-footer">Steve Jobs</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsRegex } from "react-icons/bs";
import { DiGithubBadge, DiNpm } from "react-icons/di";
import {
  SiVisualstudiocode,
  SiPostman,
  SiGit,
  SiGnubash,
  SiVite,
  SiLinux,
  SiGnuemacs,
  SiVim,
  SiMarkdown,
  SiLinuxmint,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGithubBadge />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinuxmint />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGnubash />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiVim />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGnuemacs />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiNpm />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVite />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiMarkdown />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BsRegex />
      </Col>
    </Row>
  );
}

export default Toolstack;

import React from "react";
import PropTypes from "prop-types"; // Import PropTypes

function Pre(props) {
  return <div id={props.load ? "preloader" : "preloader-none"}></div>;
}

// Add PropTypes validation
Pre.propTypes = {
  load: PropTypes.bool.isRequired, // Define load as a required boolean
};

export default Pre;
